import { poiMap, mellekPoi } from "./mapper";
import { englishToNepaliNumber } from "nepali-number";

export const filterObject = obj => {
  for (const propName in obj) {
    if (
      obj[propName] === "" ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const groupBy = key => array =>
  array.reduce((objByKey, obj) => {
    let value = obj[key];
    objByKey[value] = (objByKey[value] || []).concat(obj);
    return objByKey;
  }, {});

export const getMellekhPoiTypes = data => {
  let formId = data["formhub/uuid"];
  let deprecatedId = data["meta/deprecatedID"];
  let uuid = data._uuid;
  let photoAfter = "";
  let photoBefore = "";

  if (deprecatedId) {
    let dep = deprecatedId.slice(5);
    photoBefore = `https://kc.kobo.yipl.com.np/media/large?media_file=mellekh_poi/attachments/${formId}/${dep}/${data.photo_before}`;
    photoAfter = `https://kc.kobo.yipl.com.np/media/large?media_file=mellekh_poi/attachments/${formId}/${dep}/${data.photo_after}`;
  } else {
    photoBefore = `https://kc.kobo.yipl.com.np/media/large?media_file=mellekh_poi/attachments/${formId}/${uuid}/${data.photo_before}`;
    photoAfter = `https://kc.kobo.yipl.com.np/media/large?media_file=mellekh_poi/attachments/${formId}/${uuid}/${data.photo_after}`;
  }

  let mappedValues = {
    ward_no: data.ward_no ? englishToNepaliNumber(data.ward_no) : null,
    expenditure: data.expenditure
      ? englishToNepaliNumber(data.expenditure)
      : null,
    full_name_location: data.full_name_location,
    surveyor_name: data.surveyor_name,
    program_name: data.program_name,
    village_tole_name: data.village_tole_name,
    program_start_date: data.program_start_date
      ? englishToNepaliNumber(data.program_start_date)
      : null,
    program_end_date: data.program_end_date
      ? englishToNepaliNumber(data.program_end_date)
      : null,
    _geolocation: {
      lat: data._geolocation[0]
        ? englishToNepaliNumber(data._geolocation[0])
        : null,
      long: data._geolocation[1]
        ? englishToNepaliNumber(data._geolocation[1])
        : null
    },
    photo_before: photoBefore,
    photo_after: photoAfter,
    misc: {}
  };

  let mapper =
    data.surveyed_location_type && mellekPoi[data.surveyed_location_type]
      ? mellekPoi[data.surveyed_location_type]
      : "";

  if (mapper) {
    for (const key in mapper) {
      let tmpData = data[key];
      let tmpMap = mapper[key];

      if (tmpData && tmpMap) {
        switch (tmpMap.type) {
          case "array_string": {
            tmpData = tmpData.split(" ");

            let conversion = tmpData.map(ele => {
              if (tmpMap.values[ele]) {
                return tmpMap.values[ele];
              }
            });
            
            mappedValues.misc[tmpMap.label] = conversion.join(", ");
            break;
          }

          default:
            break;
        }
      }
    }
  }

  return mappedValues;
};

export const getPoiTypes = data => {
  let type = `poiType${data.poi_type}`;
  let mapper = poiMap[type];

  let mappedValues = {
    name_nep: data.name_nep || null,
    ward: data.ward ? englishToNepaliNumber(data.ward) : null,
    tole: data.tole || null,
    contact_person: data.contact_person || null,
    contact_number: data.contact_number || null,
    _geolocation: {
      lat: data._geolocation[0]
        ? englishToNepaliNumber(data._geolocation[0])
        : null,
      long: data._geolocation[1]
        ? englishToNepaliNumber(data._geolocation[1])
        : null
    },
    misc: {}
  };

  if (mapper) {
    for (const key in mapper) {
      let tmpMap = mapper[key];

      if (tmpMap.type === "text") {
        mappedValues.misc[tmpMap.label] = data[key] || null;
      }

      if (tmpMap.type === "conversion") {
        mappedValues.misc[tmpMap.label] = data[key]
          ? englishToNepaliNumber(data[key])
          : null;
      }

      if (tmpMap.type === "list") {
        const search = (arr, val) =>
          arr.find(ele => {
            if (ele.name === val) {
              return ele;
            }
          });

        mappedValues.misc[tmpMap.label] = data[key]
          ? search(tmpMap.children, data[key]).label || null
          : null;
      }
    }
  }

  return mappedValues;
};

/**
 * Converts english number to formatted nepali number system
 * @param {integer} num
 * @returns string
 */
export const getFormatedNepaliNumber = num => {
  let len = num.toString().length;
  let cash = [];
  const formats = [
    "one",
    "tens",
    "hundred",
    "thousand",
    "tenThousands",
    "lakh",
    "tenLakh",
    "crore",
    "tenCrore",
    "arab",
    "tenArab",
    "kharab",
    "tenKharab"
  ];
  const loopedFormats = [
    {
      label: "खरब",
      tensplace: "tenKharab",
      onesplace: "kharab"
    },
    {
      label: "अरब",
      tensplace: "tenArab",
      onesplace: "arab"
    },
    {
      label: "करोड",
      tensplace: "tenCrore",
      onesplace: "crore"
    },
    {
      label: "लाख",
      tensplace: "tenLakh",
      onesplace: "lakh"
    },
    {
      label: "हजार",
      tensplace: "tenThousands",
      onesplace: "thousand"
    },
    {
      label: "सय",
      tensplace: "tenHundred",
      onesplace: "hundred"
    },
    {
      label: "",
      tensplace: "tens",
      onesplace: "one"
    }
  ];

  do {
    let div = Math.pow(10, len - 1);
    let val = Math.floor((num / div) % 10);
    cash[formats[len - 1]] = val;
    len--;
  } while (len > 0);

  let str = "";

  for (const i in loopedFormats) {
    if (cash[loopedFormats[i].tensplace] || cash[loopedFormats[i].onesplace]) {
      let tmp =
        (cash[loopedFormats[i].tensplace] || 0) * 10 +
        cash[loopedFormats[i].onesplace];
      str = str + `${englishToNepaliNumber(tmp)} ${loopedFormats[i].label} `;
    }
  }

  return str || englishToNepaliNumber(0);
};
